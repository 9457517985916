import { graphql, useStaticQuery } from "gatsby";

const useAboutData = () => {
  const {
    allStrapiAboutSections,
    allStrapiTimelines,
    allStrapiTimelineBackground,
    allStrapiContactSection,
  } = useStaticQuery(graphql`
    query {
      allStrapiAboutSections(sort: {order: ASC, fields: strapiId}) {
        nodes {
          strapiId
          title_bs
          text_bs
          title_en
          text_en
          title_de
          text_de
        }
      }
      allStrapiTimelines(sort: {order: ASC, fields: year}) {
        nodes {
          strapiId
          year
          description_bs
          description_en
          description_de
        }
      }
      allStrapiTimelineBackground {
        nodes {
          image {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
      allStrapiContactSection {
        nodes {
          button_bs
          title_bs
          button_en
          title_en
          button_de
          title_de
          backgroundImg {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  `);

  return {
    aboutSections: allStrapiAboutSections.nodes.map((node) => node),
    timelineYears: allStrapiTimelines.nodes.map((node) => node.year),
    timelineDescriptions: {
      description_bs: allStrapiTimelines.nodes.map(
        (node) => node.description_bs,
      ),
      description_en: allStrapiTimelines.nodes.map(
        (node) => node.description_en,
      ),
      description_de: allStrapiTimelines.nodes.map(
        (node) => node.description_de,
      ),
    },
    backgroundImage:
      allStrapiTimelineBackground.nodes[0].image.childImageSharp.fluid,
    contactSectionButton: {
      button_bs: allStrapiContactSection.nodes[0].button_bs,
      button_en: allStrapiContactSection.nodes[0].button_en,
      button_de: allStrapiContactSection.nodes[0].button_de,
    },
    contactSectionTitle: {
      title_bs: allStrapiContactSection.nodes[0].title_bs,
      title_en: allStrapiContactSection.nodes[0].title_en,
      title_de: allStrapiContactSection.nodes[0].title_de,
    },
    contactSectionImage:
      allStrapiContactSection.nodes[0].backgroundImg.childImageSharp.fluid,
  };
};

export default useAboutData;
