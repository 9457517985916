import React, { useContext } from 'react';
import eu4bLogo from 'images/eu4b-logo.png';
import eu4business from 'images/EU4BLogo.png';
import eu4businessEn from 'images/EU4BLogoEn.png';
import { LanguageContext } from 'components/Layout';

import styles from './logos.module.scss';

const Logos = () => {
  const { language } = useContext(LanguageContext);
  return (
    language === 'bs' ? (
      <div className={styles.logoSection}>
        <div className={styles.logosContainer}>
          <img src={eu4business} alt="eu4b logo" className={styles.logoImage} />
          <a href="https://eu4business.ba" target="_blank" rel="noreferrer" className={styles.logoLink}>
            <img src={eu4bLogo} alt="eu4b logo" />
          </a>
        </div>
        <div className={styles.textContainer}>
          <p>
            Razvojni projekat sufinansiraju Evropska Unija (EU) i vlada SR Njemačke (BMZ) putem EU4Bussiness projekta u BiH.
          </p>
        </div>
      </div>
    ) : (
      <div>
        <div className={styles.logosContainer}>
          <img src={eu4businessEn} alt="eu4b logo" className={styles.logoImage} />
          <div className={styles.logoLink}>
            <a href="https://eu4business.ba/en" target="_blank" rel="noreferrer">
              <img src={eu4bLogo} alt="eu4b logo" />
            </a>
          </div>
        </div>
        <div className={styles.textContainerEn}>
          <p>
            The development project is co-financed by the European Union (EU) and the Government of the Federal Republic of Germany
            (BMZ) through the EU4Bussiness project in BiH.
          </p>
        </div>
      </div>
    ));
}

export default Logos;
