import React from 'react';
import Layout from 'components/Layout';
import ScrollTopArrow from 'utilities/scrollToTop';

import homePic from 'images/home.jpg';
import './index.scss';
import Contact from 'components/About/Contact';
import Projects from 'components/Projects/index';

const ProjectsPage = () => (
  <Layout title="Projekti podrške" description="MEHANIČKA OBRADA. ODSJEK MEHANIČKE OBRADE - Skladište materijala, Skladište alata, CNC tokarenje, CNC glodanje, Montaža" image={homePic}>
    <ScrollTopArrow />
    <Projects />
    <Contact />
  </Layout>
);

export default ProjectsPage;
