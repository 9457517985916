import React, { useContext, useState } from 'react';
import { LanguageContext } from 'components/Layout';
import useWindowWidth from 'utilities/windowWidth';
import useProjectsData from 'queries/ProjectsQueries';
import Slider from 'react-slick';
import Markdown from 'react-markdown';
import Image from 'gatsby-image';
import classNames from 'classnames';

import backgroundImage from 'images/coverImage.jpg';
import eu4business from 'images/EU4BLogo.png';
import eu4businessen from 'images/EU4BLogoEn.png';
import gizLogo from 'images/giz-logo.jpg';
import eu4bLogo from 'images/eu4b-logo.png';
import iloLogo from 'images/ilo-logo.png';
import undpLogo from 'images/undp-logo.png';
import gatLogo from 'images/gatLogo.png';
import styles from './projects.module.scss';
import Logos from 'components/Home/Logos';

const Projects = () => {
  const { language } = useContext(LanguageContext);
  const { projects } = useProjectsData();
  const [windowWidth, setWindowWidth] = useState(0);
  useWindowWidth({ setWindowWidth });
  const mobileSettings = {
    infinite: true,
    autoplay: true,
    autoplaySpeed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: false,
    arrows: false,
  };
  return (
    <>
      <div className={styles.imageContainer}>
        <img alt="GAT" src={backgroundImage} className={styles.backgroundImage} />
      </div>
      {projects.map((project) => (
        <div className={styles.projectsContainer} key={project.strapiId}>
          <h2>{project[`title_${language}`]}</h2>
          <div className={styles.topLogos}>
            <img alt="eu4b logo" src={eu4business} className={styles.logoImage} />
            <img alt="giz logo" src={gizLogo} className={styles.logoImage} />
          </div>
          <Markdown children={project[`text_${language}`]} className={styles.text} />
          <Image alt="machine" fluid={project.image.childImageSharp.fluid} className={styles.projectImage} />
        </div>
      ))}
      <Logos />
      {windowWidth > 600 ? (
        <div className={styles.logoSection}>
          <img alt="eu4b logo" src={language === 'bs' ? eu4business : eu4businessen} className={styles.logoImage} />
          <img alt="eu4b logo" src={eu4bLogo} className={styles.logoImage} />
          <img alt="ilo logo" src={iloLogo} className={styles.logoImage} />
          <img alt="undp logo" src={undpLogo} className={classNames(styles.logoImage, styles.undpLogo)} />
          <img alt="GAT logo" src={gatLogo} className={classNames(styles.logoImage, styles.gatLogo)} />
        </div>
      ) : (
        <Slider {...mobileSettings} className={styles.logoSlider}>
          <div className={styles.slide}>
            <img alt="eu4b logo" src={eu4bLogo} className={styles.logoImage} />
          </div>
          <div className={styles.slide}>
            <img alt="ilo logo" src={iloLogo} className={styles.logoImage} />
          </div>
          <div className={styles.slide}>
            <img alt="undp logo" src={undpLogo} className={styles.undpLogo} />
          </div>
          <div className={styles.slide}>
            <img alt="GAT logo" src={gatLogo} className={styles.gatLogo} />
          </div>
          <div className={styles.slide}>
            <img alt="eu4b logo" src={language === 'bs' ? eu4business : eu4businessen} className={styles.logoImage} />
          </div>
        </Slider>
      )}
    </>
  );
};

export default Projects;
