import React, { useContext } from 'react';
import BackgroundImage from 'gatsby-background-image';
import { Link } from 'gatsby-plugin-intl';

import useAboutData from 'queries/AboutQueries';
import { LanguageContext } from 'components/Layout';
import styles from './about.module.scss';

const Contact = () => {
  const { language } = useContext(LanguageContext);
  const { contactSectionButton, contactSectionTitle, contactSectionImage } = useAboutData();
  return (
    <BackgroundImage fluid={contactSectionImage} className={styles.backgroundImage}>
      <div className={styles.contactSectionContainer}>
        <Link to="/contact" ><button type="button" className={styles.contactButton}>{contactSectionButton[`button_${language}`]}</button></Link>
        <p>{contactSectionTitle[`title_${language}`]}</p>
      </div>
    </BackgroundImage>
  );
};

export default Contact;
