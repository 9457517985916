import { graphql, useStaticQuery } from 'gatsby';

const useProjectsData = () => {
  const { allStrapiProjects: { nodes: projects } } = useStaticQuery(graphql`
  {
    allStrapiProjects {
      nodes {
        strapiId
        title_bs
        text_bs
        title_en
        text_en
        title_de
        text_de
        image {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
  `);

  return {
    projects,
  };
};

export default useProjectsData;
